.basicWidthAndHeight {
    width:fit-content;
    height:fit-content;
}

.integrationTitle {
    color: #212326;
    text-align: center;
    font-family: 'NunitoSansExtraBold' !important;
    font-size:18px;
    margin-top:30px;
    margin-bottom:30px;
}
.manchaeffect {
    position: absolute;
    left: 0;
    width: 104%;
    opacity: 0.6;
    height:fit-content;
}


.subtitleOrangeText  {
    padding: 20px 30px;
    /* line-height: 31px; */
    font-size: 19px;
    max-width: 500px;
    text-align: justify;
    font-family: 'NunitoSansExtraBold';
    color: #212326;
    line-height: 35px;
    
}
.listh5 {
    font-size: 18px;
    color: #212326;
    font-family: 'NunitoSansSemiBold';
    max-width: 80%;
    margin-top:20px !important;
}

.mapChile {
    max-width: 800px;
    width: 100%;
    height:fit-content;
}

.analytcsIcon {
    width:60%;
    height: fit-content;

}
.bsaleIcon {
    width: 60%;
    height: fit-content;
}
.mercadoPagoIcon {
    width: 60%;
    height: fit-content;
}
.webpayIcon {
    width: 60%;
    height: fit-content;
}
.flowIcon {
    width: 60%;
    height: fit-content;
}
.chileexpressIcon {
    width: 60%;
    height: fit-content;

}

.techColIcon {
    text-align:center;
}

.integrationContainer {
    height: fit-content !important;
    margin-top:30px;
    margin-bottom: 30px;
}

.buttonHeader {
    width: auto !important;
    height: auto !important;
    margin: 1.125rem 12.125rem 5.625rem 9.707rem;
    padding: 9px 17px !important;
    border-radius: 57px;
    background-color: #ff5f16 !important;
    border: none;
    text-align: center;
    color: white !important;
    width: auto;
    height: 2.563rem;
    margin: 0.375rem 2.688rem 0.375rem 0;
    font-family: 'NunitoSansBold' !important;
    font-size: 16px !important;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}

.boxorange {
    max-width: 50%;
}
.pictureBigOrange {
    width: 100%;
    height:fit-content;
}
.containerorange {
    display: inline-flex;
}
.svgContainerLogo {
    margin:0 auto;
    text-align: center;
    margin-top:25px;
}
.homePrice {
    font-weight:700;
    font-family: 'NunitoSansExtraBold';
    margin: 5px 0px;
}

.objectgator {
    max-width: 620px;
    margin-bottom:20px;
}
/* object {
   
} */
.logoPicture {
    max-width: 800px;
    
}

.OrangeTitle {
    margin-top: 40px;
    padding: 30px;
    color: white;
    font-size: 30px;
    font-family: 'NunitoSansSemiBold';
}
.OrangeSubtitle {
    padding: 68px;
    color: white;
    font-size: 50px;
    font-family: 'NunitoSansBold';
}

.salesSubtitle {
    padding: 68px;
    color: black;
    font-size: 50px;
    font-family: 'NunitoSansBold';
}

.mapText {
    padding: 20px;
    color: #ff5f16!important;
    font-size: 35px;
    font-family: 'NunitoSansExtraBold';
}

.OrangeContainer {
    background: #ff5f16!important;
    width: 100%;
    height: fit-content !important;
    margin-bottom: 30px !important;
    padding-right: 0px !important;
    padding-left:0px !important;
}

.SalesContainer {
    width: 100%;
    height: fit-content !important;
    margin-bottom: 30px !important;
    padding-right: 0px !important;
    padding-left:0px !important;
}

.slick-dots li button {
    font-size: 14px !important; 
  }

.Background {
    height: fit-content !important;
    margin: 3rem 0;
    /* background: url('../../../assets/Layer_2.png') no-repeat -120rem -30rem/180%; */
}


.Container2 {
    height: 130rem;
    margin: 10.313rem 0 0rem 0.031rem;
    padding: 0rem 2rem 0rem 2rem;
    border-radius: 20px;
    background-color: white;
}

.Elipse {
    background: url('../../../assets/elipse-17.png') no-repeat 68rem 0rem/60%;
}

.Trazado63 {
    width: 116.843rem;
    height: 161.65rem;
    object-fit: contain;
    opacity: 0.07;
    background-color: #ededed;
}

.Rectngulo11 {
    height: 132.875rem;
    margin: 10.313rem 0 12.125rem 0.031rem;
    padding: 13.188rem 0 18rem 6.238rem;
    border-radius: 20px;
    background-color: #1a1a1a;

}

.Logo {
    width: 200px;
}


.NavItem {
    width: auto;
    height: 2.563rem;
    margin: 0.375rem 2.688rem 0.375rem 0;
    font-family: 'NunitoSansBold';
    font-size: 1.875rem;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: black !important;
}



.NavBar {
    max-width: 1920px;
    margin: 0px auto;
    padding: 0 5.75rem !important;
    /* background-color: transparent !important; */
    height: 8rem;
    border-radius: 0 !important;
    background: #f5ebe0;
}

.BorderNavItem {
    width: auto;
    height: 3.313rem;
    padding: 0.3rem 1rem !important;
    border-radius: 57px;
    border: solid 1px #ff5f16;
}

.Rectangle {
    height: 45rem;
    width: 95%;
    margin: 0px 16px !important;
    border-radius: 24px 0px 0 24px;
    background-color: white;
}

.Digitaliza {
    width: 38rem;
    height: 5.5rem;
    margin: 15.625rem 3.375rem 1.675rem 5.207rem;
    font-family:'NunitoSansExtraBold';
    font-size: 3.888rem !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.17 !important;
    letter-spacing: normal !important;
    text-align: left;
    color: black !important;
    display: inline-flex;
    text-transform: none !important;
}

.TabSpan {
    margin-right: 6px;
}

.TusProductos {
    width: 27rem;
    margin: 15.625rem 3.375rem 1.675rem 2.207rem !important;
    font-family: 'NunitoSansLight';
    font-size: 1.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal !important;
    text-align: left;
    color: black !important;
}

.Contactanos {
    font-family: 'NunitoSansBold';
    font-size: 1.688rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: white !important;
    margin: auto;
}

.ButtonContactanos {
    width: 17.875rem;
    height: 3.563rem;
    margin: 1.125rem 12.125rem 5.625rem 9.707rem;
    padding: 0.407rem 0em 0.406rem 0rem;
    border-radius: 57px;
    box-shadow: 0 30px 30px 0 rgb(242 103 34 / 15%) !important;
    background-color: #ff5f16 !important;
    border: none;
    text-align: center;
}

.Principal {
    height: 100%;
    margin: 0px 0 0 -11.187rem;
    background-color: #000000;
    /* background: url('../../../assets/Pack/principal1.png') no-repeat 12rem center/106%; */
    background-size: cover !important;
}

.Height {
    height: 100%;
}

.HazloSimpleImg {
    width: 43.125rem;
    height: 40.75rem;
    margin: 0rem 0rem 0.563rem 1.375rem;
    border-radius: 10px;
}

.HazloSimple {
    width: 30rem;
    height: 4.312rem;
    margin: 4.513rem 0 0 0;
    font-family: 'NunitoSansBold';
    font-size: 3.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: black !important;
}

.HazloSimpleItem {
    width: 37.188rem;
    height: 18.188rem;
    margin: 1.875rem 1.875rem 0rem 0.708rem;
    font-family: 'NunitoSansLight';
    font-size: 1.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: black;
}

.HazloSimpleContainer {
    width: 37.188rem;
}

.Linea1 {
    width: 37.813rem;
    height: 0.375rem;
    margin: 1.563rem 1.875rem 1.938rem -1.5rem;
}

.RemovePadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ConQuienesTrabajamos {
    height: 4.313rem;
    margin: 1.563rem 21.75rem 0 0rem;
    font-family: 'NunitoSansBold';
    font-size: 3.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: black !important;
}

.Images {
    width: 120px !important;
    height: 27px;
}

.ImagesContainer {
    width: 100%;
    margin: auto;
}


.Footer {
    height: 35.438rem;
    margin: 0rem 0 0 0.031rem;
    padding: 10.438rem 0rem 4.75rem 0rem;
    background-color: #222222 !important;
    left: 0;
    bottom: 0;
    position: sticky;
}

.Telfono {
    width: 5.063rem;
    height: auto;
    font-family: 'NunitoSansSemiBold';
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.67;
    letter-spacing: normal;
    text-align: left;
    color: white;
    margin-top: -5rem !important;
}

.VenezuelaChile {
    width: auto;
    height: 3.875rem;
    margin: 0rem 0rem 0rem 0rem;
    font-family: 'NunitoSansLight';
    font-size: 1.5rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.Lnea2 {
    width: 100%;
    height: 0.03rem;
    margin: 0.125rem 0 4.938rem 0;
}

.TrminosPrivacidad {
    width: auto;
    height: 1.375rem;
    font-family: 'NunitoSansSemiBold';
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: right;
    color: white;
    margin-right: 5rem;
}

.FooterLogo {
    margin-left: 5rem;
    width: 65%;
    height:fit-content;
}

.Lumarketo2021 {
    width: 6rem;
    height: 1.375rem;
    margin-left: 5rem;
    font-family: 'NunitoSansSemiBold';
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

.svgContainer {
    height: auto;
    text-align: right;
    margin-right: 5rem;
}

.svg {
    width: 2.502rem;
    height: 2.502rem;
    margin: 0 0.342rem 0 0.342rem;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-flex;
    text-align: right;
}

.svgImg {
    width: 2rem;
    height: 2rem;
    margin: auto;
}

.ComoFunciona {
    width: 16.313rem;
    height: 8.063rem;
    margin: 0 4.563rem 2.625rem 0.595rem;
    font-family: 'NunitoSansSemiBold';
    font-size: 3.125rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: black !important;
    text-transform: uppercase;
    display: inline-flex;
}


.ComoFuncionaText {
    width: 18rem;
    height: 5.5rem;
    margin: 2.625rem 2.875rem 1.75rem 0.595rem;
    font-family: 'NunitoSansLight';
    font-size: 1.5rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: black;
    display: -webkit-box;
}

.VerTodo {
    width: 5.688rem;
    height: 1.75rem;
    margin: 0 0 0 0.438rem;
    font-family: 'NunitoSansExtraBold';
    font-size: 1.313rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.67;
    letter-spacing: normal;
    text-align: left;
    color: #ff862c;
}

.VerTodo:hover {
    color: #ff862c;
}

.VerTodoArrow {
    width: 100%;
    height: 100%;
    margin: 5.19rem 0.349rem 0 0;
    font-family: 'NunitoSansExtraBold';
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ff862c;
}

.PlansContainer {
    margin-top: 12rem;
    z-index: 5;
    position: absolute;
}


.HiddenRows {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.Rectangulo {
    width: 102.5rem;
    height: 51.25rem;
    margin: 11.625rem 8.719rem 19.125rem 0.595rem;
    padding: 0 0 0 8.25rem;
    border-radius: 24px;
    background-color: white;
}

.Planes {
    width: 2.875rem;
    height: 1rem;
    margin: 5.13rem 15.154rem 0 0.003rem;
    font-family: 'NunitoSansSemiBold';
    font-size: 0.75rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.67;
    letter-spacing: normal;
    text-align: left;
    color: #ff862c;
    text-transform: uppercase;
}

.PlanInicia {
    width: 18.375rem;
    height: 4.313rem;
    margin: 4.875rem 9.188rem 0.563rem 0;
    font-family: 'NunitoSansSemiBold';
    font-size: 3.125rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: black;
}

.PlanIniciaText {
    width: 18rem;
    height: 6.875rem;
    margin: 0.563rem 9.313rem 0.938rem 0.25rem;
    font-size: 1rem;
    font-family: 'NunitoSansLight';
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: black;
}

.VerMs {
    width: 4.813rem;
    height: 1.75rem;
    font-size: 1.313rem;
    font-family: 'NunitoSansSemiBold';
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.67;
    letter-spacing: normal;
    text-align: left;
    color: #ff862c;
}

.VerMs:hover {
    color: #ff862c;
}

.Elipse14 {
    width: 75.344rem;
    height: 86.063rem;
    margin: 7.563rem 0 0 17.875rem;
    padding: 78.938rem 51rem 3.563rem 6.469rem;
    border: solid 3px #ffffff !important;
    border-radius: 50%;
}


.SecondButton {
    padding: 0.622rem 4.102rem 0.628rem 4.023rem !important;
}

.Lnea3 {
    width: auto;
    height: 2.863rem;
    border-bottom: solid 2px #ff5f16;
}

.ActiveBorderNavItem {
    /* background-color: #000000; */
    border: none;
}

@media (min-width: 0px) {
    .Lumarketo2021 {
        width: 100%;
        height: 1.375rem;
        margin: 0rem;
        font-size: 1rem;
    }

    .TrminosPrivacidad {
        width: 100%;
        height: 1.375rem;
        margin: 0rem;
        font-size: 1rem;
        text-align: right;
    }

    .Telfono {
        margin-top: 0rem !important;
    }

    .FooterLogo {
        margin-left: 0rem;
        width: 30%;
        height: fit-content;
    }

    .svgContainer {
        text-align: left;
        margin-bottom: 1rem;
    }


    .ComoFunciona {
        width: 20rem;
        text-align: center;
        font-size: 1.888rem;
        height: 1.625rem;
        margin: 11.625rem 0 0.693rem 0.522rem;
    }

    .ComoFuncionaText {
        text-align: justify;
        width: auto;
        height: 4.563rem;
        margin: 1.693rem 1.063rem 0 1.188rem;
    }

    /* .Logo {
        width: 140px;
        height: 17px;
    } */

    .NavBar {
        padding: 0 !important;
    }

    .Background {
        height: auto;
        margin-top: -1%;
        /* background: url('../../../assets/Layer_2.png') no-repeat -70rem -10rem/250%; */
    }

    .Rectangle {
        width: 105%;
        height: fit-content !important;
        margin: 13.329rem 0 5.313rem 0rem;
        padding: 0 0 0 0.875rem;
    }

    .Digitaliza {
        width: 12.438rem !important;
        height: 3rem !important;
        margin: 6.063rem 0rem 0.25rem -1.5rem !important;
        font-size: 1.5rem !important;
    }

    .HazloSimpleImg {
        width: 100%;
        height: 25.813rem;
        margin: 1.25rem auto;
        text-align: center;
    }

    .Linea1 {
        width: 6rem;
        height: 0.375rem;
        margin: 1.9rem 3rem 2.313rem -2.55rem;
    }

    .TusProductos {
        width: 10rem;
        height: 5.188rem;
        margin: 1.5rem 0.625rem 0rem 0.8rem;
        font-size: 1.2rem;
    }

    .ButtonContactanos {
        width: 10rem;
        height: 2.5rem;
        margin: 22rem 0rem 0rem 8rem;
    }

    .Contactanos {
        font-size: 0.875rem;
    }


    .Principal {
        height: 99.8%;
        margin: 0px 0 0 -7.813rem;
        background-color: #000000;
        /* background: url('../../../assets/Pack/principal-sm.png') no-repeat 13rem center/100%; */
    }

    .TabImg {
        width: 15px;
        height: 15px;
    }

    .TabImg3 {
        width: 20px;
        height: 20px;
    }

    .HazloSimple {
        text-align: center;
        font-size: 1.888rem;
        width: 100%;
    }

    .HazloSimpleContainer {
        width: 30rem;
    }

    .ConQuienesTrabajamos {
        width: 100%;
        height: auto;
        margin: 8rem 0rem 0rem 1rem;
        font-size: 1.588rem;
    }

    .ImagesContainer {
        width: 100%;
        margin: 2rem auto 4.5rem auto;
    }

    .PlansContainer {
        width: 90%;
    }


    .Rectangulo {
        width: 25rem;
        height: 51.048rem;
        margin: 30rem auto 0rem auto;
        padding: 0;
    }

    .Planes {
        width: 2.875rem;
        height: 1rem;
        margin: 0.567rem 19.176rem 0.804rem 1.929rem;
        font-size: 0.75rem;
    }

    .PlanInicia {
        width: 10rem;
        height: 1.625rem;
        margin: 0.204rem 15.051rem 0.459rem 1.929rem;
        font-size: 1.188rem;
    }

    .PlanIniciaText {
        width: 19.5rem;
        height: 7.688rem;
        margin: 0.459rem 2.552rem 0 1.927rem;
        font-size: 1.063rem;
    }

    .VerMs {
        width: 7.75rem;
        height: 1rem;
        margin: 1.937rem 0.281rem 2.376rem 0;
        font-size: 0.75rem;
    }

    .Elipse14 {
        background: url('../../../assets/Pack/secundario1.png')no-repeat 5.5rem 9.1rem/67.5%;
        width: 100%;
        height: 36.157rem;
        margin: -13rem 0 0 -7.125rem;
        padding: 40rem 40rem 0.563rem 0.469rem;
    }


    .SecondButton {
        padding: 0.422rem 0.102rem 0.628rem 2.6rem !important;
        display: -webkit-box;
        position: absolute;
        margin: 5rem !important;
        width: 10.688rem;
        height: 2.187rem;
    }

    .Lnea2 {
        width: 100%;
        height: 0.03rem;
        margin: 0.125rem 0 0.938rem 0;
    }

    .NavItem {
        text-align: left;
    }
}

/* // Small devices (landscape phones, 576px and up) */



@media (min-width: 425px) {

    .NavBar {
        padding: 0 !important;
    }

    .Background {
        height: auto;
        margin-top: -1%;
        /* background: url('../../../assets/Layer_2.png') no-repeat -70rem -10rem/250%; */
    }

    .Rectangle {
        width: 100%;
        height: fit-content !important;
        margin: 15.329rem 0 5.313rem 1.513rem;
        padding: 0 0 0 0.875rem;
    }

    .Digitaliza {
        width: 15.438rem !important;
        height: 3rem !important;
        margin: 6.063rem 0rem 0.25rem -0.8rem !important;
        font-size: 2rem !important;
    }

    .HazloSimpleImg {
        width: 100%;
        height: 30.813rem;
        margin: 1.25rem auto;
        text-align: center;
    }

    .Linea1 {
        width: 14rem;
        height: 0.375rem;
        margin: 1.9rem 3rem 2.313rem -2.55rem;
    }

    .TusProductos {
        width: 12rem;
        height: 5.188rem;
        margin: 1.5rem 0.625rem 0rem 1.65rem;
        font-size: 1.4rem;
    }


    .ButtonContactanos {
        width: 10rem;
        height: 2.5rem;
        margin: 22rem 0rem 0rem 8rem !important;
    }

    .Contactanos {
        font-size: 1.1rem;
        margin: 0 auto;
    }


    .Principal {
        height: 99.8%;
        margin: 0px 0 0 -7.813rem;
        background-color: #000000;
        /* background: url('../../../assets/Pack/principal-sm.png') no-repeat 11.5rem center/145%; */
    }

    .TabImg {
        width: 15px;
        height: 15px;
    }

    .HazloSimple {
        text-align: center;
        font-size: 1.888rem;
        width: 100%;
    }

    .HazloSimpleContainer {
        width: 100%;
        margin: auto;
    }

    .ConQuienesTrabajamos {
        width: 100%;
        height: auto;
        margin: 8rem 0rem 0rem 1rem;
        font-size: 2rem;
    }

    .ImagesContainer {
        width: 100%;
        margin: 2rem auto 4.5rem auto;
    }

    .Rectangulo {
        width: 35rem;
        height: 51.048rem;
        margin: 35rem auto 0rem auto;
        padding: 0;
    }

    .Planes {
        width: 2.875rem;
        height: 1rem;
        margin: 0.567rem 19.176rem 0.804rem 1.929rem;
        font-size: 0.75rem;
    }

    .PlanInicia {
        width: 10rem;
        height: 1.625rem;
        margin: 0.204rem 15.051rem 0.459rem 1.929rem;
        font-size: 1.188rem;
    }

    .PlanIniciaText {
        width: 19.5rem;
        height: 7.688rem;
        margin: 0.459rem 2.552rem 0 1.927rem;
        font-size: 1.063rem;
    }

    .VerMs {
        width: 7.75rem;
        height: 1rem;
        margin: 1.937rem 0.281rem 2.376rem 0;
        font-size: 0.75rem;
    }

    .Elipse14 {
        background: url('../../../assets/Pack/secundario1.png')no-repeat 1rem 11.2rem/95.2%;
        width: 100%;
        height: 36.157rem;
        margin: -16rem auto 0 -3.5rem;
        padding: 40rem 40rem 0.563rem 0.469rem;
    }

    .SecondButton {
        padding: 0.422rem 0.102rem 0.628rem 2rem !important;
        display: -webkit-box;
        position: absolute;
        margin: 10rem !important;
        width: 10.688rem;
        height: 2.187rem;
    }
}

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .Container2 {
        height: 80rem;
    }

    .Lumarketo2021 {
        width: 6rem;
        height: 1.375rem;
        margin-left: 5rem;
        font-size: 1.5rem;
    }

    .TrminosPrivacidad {
        width: auto;
        height: 1.375rem;
        font-size: 1.5rem;
        margin-right: 5rem;
    }


    .Lnea2 {
        width: 100%;
        height: 0.03rem;
        margin: 0.125rem 0 4.938rem 0;
    }

    .FooterLogo {
        margin-left: 5rem;
        width: 65%;
        height: fit-content;

    }

    .Lumarketo2021 {
        width: 18rem;
        height: 1.375rem;
        margin-left: 5rem;
    }

    .svgContainer {
        height: auto;
        text-align: right;
        margin-right: 5rem;
    }

    .Telfono {
        width: 5.063rem;
        height: auto;
        margin-top: -5rem !important;
    }

    .Logo {
        width:390px !important;
        height:35px !important;
    }

    .NavBar {
        padding: 0 8.75rem !important;
        height: 8rem;
        margin: 0 auto;
    }

    .Background {
        height: 161.65rem;
        margin-top: -1%;
        /* background: url('../../../assets/Layer_2.png') no-repeat -90rem -30rem/210%; */
    }

    .Rectangle {
        height: fit-content !important;
        width: 90%;
        margin: 13.813rem 0 0 8.75rem;
    }

    .Digitaliza {
        width: 20rem !important;
        height: 4.5rem !important;
        margin: 12.625rem 3.375rem 1.675rem 0.207rem !important;
        font-size: 2.5rem !important;
    }


    .ComoFunciona {
        width: 18rem !important;
        height: 4.5rem !important;
        margin: 12.625rem 3.375rem 1.675rem 0.207rem !important;
    }

    .HazloSimpleImg {
        width: 32.25rem;
        height: 30.75rem;
        margin: 0rem 0rem 0.563rem 2.375rem;
    }

    .Linea1 {
        width: 20.813rem;
        height: 0.375rem;
        margin: 1.563rem 1.875rem 1.938rem -1.5rem;

    }

    .TusProductos {
        width: 15.688rem;
        height: 7.75rem;
        margin: 0.375rem 2.813rem 1.125rem 4.667rem;
        font-size: 1.763rem;
    }

    .ButtonContactanos {
        width: 12.875rem;
        height: 3.563rem;
        margin: 5.125rem 12.125rem 5.625rem 4.667rem !important;
    }

    .Contactanos {
        font-size: 1.488rem;
    }

    .Principal {
        height: 100%;
        margin: 0px 0 0 -11.187rem;
        background-color: #000000;
        /* background: url('../../../assets/Pack/principal-md.png') no-repeat 12rem center/125%; */
    }

    .TabImg {
        width: auto;
        height: auto;
    }

    .TabImg1 {
        width: 1.916rem;
        height: 1.916rem;
    }

    .HazloSimple {
        width: 18.25rem;
        height: 2.563rem;
        margin: 1.513rem 0 0 -3rem;
        font-size: 1.875rem;
    }

    .HazloSimpleContainer {
        width: 30.625rem;
        margin: 0 1rem 0 0;
    }

    .ConQuienesTrabajamos {
        width: 100%;
        height: 4.313rem;
        margin: 1.563rem 21.75rem 0 0rem;
        font-size: 2.875rem;
    }

    .ImagesContainer {
        width: 100%;
        margin: auto;
    }

    .HazloSimpleItem {
        font-size: 1.5rem;
    }

    .ComoFunciona {
        font-size: 1.875rem;
        text-align: inherit;
    }

    .ComoFuncionaText {
        font-size: 1.2rem;
        margin: 0.2rem 0rem 0rem 3.1rem;
        height: 6.5rem;
        text-align: inherit;
    }

    .VerTodo {
        margin-left: 3.1rem;
    }

    .Rectangulo {
        width: 58.984rem;
        height: 31.157rem;
        margin: 0.899rem auto 10.925rem auto;
        padding: 0 0 0 3.741rem;
    }

    .Planes {
        width: 5.063rem;
        height: 1.75rem;
        margin: 4.375rem 22.125rem 0rem 0.375rem;
        font-size: 0.75rem;
    }

    .PlanInicia {
        width: 11.063rem;
        height: 2.563rem;
        margin: 1.13rem 6.966rem 0.402rem 0.003rem;
        font-size: 1.875rem;
    }

    .PlanIniciaText {
        width: 14.25rem;
        height: 9.625rem;
        margin: 0.402rem 3.781rem 1.937rem 0;
        font-size: 1rem;
    }

    .VerMs {
        width: 7.75rem;
        height: 1rem;
        margin: 1.937rem 0.281rem 2.376rem 0;
        font-size: 0.75rem;
    }

    .Elipse14 {
        width: 37.212rem;
        height: 31.157rem;
        margin: -8rem 0 0 -2.125rem;
        padding: 45rem 40rem 0.563rem 3.469rem;
        background: url('../../../assets/Pack/secundario.png') no-repeat -24rem 3.65rem/146.8%;
    }

    .SecondButton {
        padding: 0.622rem 2.102rem 0.628rem 2.023rem !important;
        display: -webkit-box;
        position: absolute;
        margin: -5rem auto 0 auto !important;
    }

    .ButtonContainer {
        margin: 0 35%;
        width: 100%;
        height: 4rem;
    }
}

/* // Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .Container2 {
        height: 120rem;
    }

    .Logo {
        width:293px !important;
        height:35px !important;
    }

    .NavBar {
        padding: 0 8.75rem !important;
        height: 8rem;
    }

    .Background {
        height: 161.65rem;
        margin-top: -1%;
        /* background: url('../../../assets/Layer_2.png') no-repeat -90rem -30rem/180%; */
    }

    .Rectangle {
        height: fit-content !important;
        width: 92.5%;
        margin: 13.813rem 0 0 8.75rem;
    }

    .Digitaliza {
        width: 38rem !important;
        height: 4.5rem !important;
        margin: 10.625rem 3.375rem 1.675rem 0.207rem !important;
        font-size: 3.788rem !important;
        color: #ff5f16 !important

    }

    .HazloSimpleImg {
        width: 40.125rem;
        height: 40.75rem;
        margin: 0rem 0rem 0.563rem 8.375rem;
    }

    .Linea1 {
        width: 37.813rem;
        height: 0.375rem;
        margin: 1.563rem 1.875rem 1.938rem -1.5rem;

    }

    .TusProductos {
        width: 24rem !important;
        margin: 3.375rem 2.813rem 1.125rem 4.707rem !important;
        font-size: 27px !important;
    }

    .ButtonContactanos {
        width: 25.875rem;
    height: 5.563rem;
    margin: 1.125rem 12.125rem 5.625rem 9.707rem !important;
    }

    .Contactanos {
        font-size: 21px !important;

    }

    .Principal {
        height: 100%;
        margin: 0px 0 0 -11.187rem;
        background-color: #000000;
        /* background: url('../../../assets/iStock-1200301158-min-compress.jpg') no-repeat 12rem center/95%; */
    }

    .TabImg {
        width: auto;
        height: auto;
    }

    .HazloSimple {
        width: 30rem;
        height: 4.312rem;
        margin: 4.513rem 0 0 -2.4rem;
        font-size: 3.125rem;
    }

    .HazloSimpleContainer {
        width: 37.188rem;
        margin: 0 0 0 2.5rem;
    }

    .ConQuienesTrabajamos {
        height: 4.313rem;
        margin: 1.563rem 21.75rem 0 0rem;
        font-size: 3.125rem;
    }

    .ImagesContainer {
        width: 100%;
        margin: auto;
    }

    .ComoFuncionaText {
        font-size: 1.2rem;
        margin: 0.2rem 0rem 0rem 3.1rem;
        height: 6.5rem;
    }

    .Rectangulo {
        width: 70rem;
        height: 51.25rem;
        margin: 11.625rem 8.719rem 19.125rem 2.7rem;
        padding: 0 0 0 8.25rem;
    }

    .Planes {
        width: 5.063rem;
        height: 1.75rem;
        margin: 11.375rem 22.125rem 4.875rem 0.375rem;
        font-size: 1.313rem;
    }

    .PlanInicia {
        width: 22.375rem;
        height: 4.313rem;
        margin: 4.875rem 9.188rem 0.563rem 0;
        font-size: 3.125rem;
    }

    .PlanIniciaText {
        width: 18rem;
        height: auto;
        margin: 0.563rem 9.313rem 0.938rem 0.25rem;
        font-size: 1.2rem;
    }

    .ButtonContainer {
        margin: 0 40%;
        width: 100%;
        height: 4rem;
    }

    .VerMs {
        width: 4.813rem;
        height: 1.75rem;
        font-size: 1.313rem;
    }

    .Elipse14 {
        width: 90.344rem;
        height: 90.063rem;
        margin: -17.437rem 0 0 -1.125rem;
        padding: 78.938rem 51rem 3.563rem 6.469rem;
        background: url('../../../assets/Pack/secundario.png') no-repeat -39rem 10.65rem/117.8%;
    }

    .SecondButton {
        padding: 0.622rem 4.102rem 0.628rem 4.023rem !important;
        display: -webkit-box;
        position: absolute;
        margin: -15rem auto 0 auto !important;
    }

    .NavItem {
        text-align: left;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {

    .Container2 {
        height: 125rem;
    }

    .Logo {
        width:295px !important;
        height:48px !important;
    }
    .NavBar {
        padding: 10px !important;
        height: 8rem;
        margin: 0px auto;
    }

    .Background {
        height: 161.65rem;
        margin-top: -1%;
        /* background: url('../../../assets/Layer_2.png') no-repeat -120rem -30rem/150%; */
    }

    .Rectangle {
        height: fit-content !important;
        width: 94.8%;
        margin: 13.813rem 0 0 8.75rem;
    }

    .Digitaliza {
        width: auto !important;
        height: auto !important;
        /* margin: 10.625rem 3.375rem 1.675rem 2.207rem !important; */
        font-size: 45px !important;
    line-height: 63px !important;
    letter-spacing: -1.9px !important;
        color: #ff5f16 !important
    }

    .HazloSimpleImg {
        width: 43.125rem;
        height: 40.75rem;
        margin: 0rem 0rem 0.563rem 15.375rem;
    }

    .Linea1 {
        width: 37.813rem;
        height: 0.375rem;
        margin: 1.563rem 1.875rem 1.938rem -1.5rem;

    }

    .TusProductos {
        width: 27rem !important;
        margin: 0.625rem 3.375rem 1.675rem 2.207rem !important;
        font-size: 27px !important;
    }

    .ButtonContactanos {
        width: 25.875rem;
    height: 5.563rem;
    margin: 1.125rem 12.125rem 5.625rem 9.707rem !important;
    }

    .Contactanos {
        font-size: 21px !important;
    }

    .Principal {
        height: 99.3%;
        margin: 0px 0 0 -11.187rem;
        background-color: #000000;
        /* background: url('../../../assets/iStock-1200301158-min-compress.jpg') no-repeat 12rem center/95%; */
    }

    .TabImg {
        width: auto;
        height: auto;
    }

    .HazloSimple {
        width: 30rem;
        height: 4.312rem;
        margin: 4.513rem 0 0px -3rem;
        font-size: 3.125rem;
    }

    .HazloSimpleContainer {
        width: 80%;
        margin: 0 0 0 2rem;
    }

    .ConQuienesTrabajamos {
        height: 4.313rem;
        margin: 1.563rem 21.75rem 0 0rem;
        font-size: 3.125rem;
    }

    .ImagesContainer {
        width: 100%;
        margin: auto;
    }

    .HazloSimpleItem {
        font-size: 1.875rem;
    }

    .TabImg1 {
        width: 2.616rem;
        height: 2.616rem;
    }

    .TabImg2 {
        width: 2.616rem;
        height: 2.616rem;
    }

    .ComoFunciona {
        width: 16.313rem;
        height: 8.063rem;
        margin: 0 4.563rem 2.625rem 0.595rem;
        text-align: inherit !important;
        font-size: 2.5rem;
    }

    .ComoFuncionaText {
        width: 18rem !important;
        height: auto !important;
        margin: 0.625rem 2.875rem 2.75rem 3.195rem !important;
        font-size: 1.5rem;
    }

    .VerTodo {
        width: 5.688rem;
        height: 1.75rem;
        margin: 0 0 0 4.438rem;
    }

    .TabImg3 {
        width: 20px;
        height: 20px;
    }

    .Rectangulo {
        width: 102.5rem;
        height: 51.25rem;
        margin: 11.625rem auto 19.125rem auto;
        padding: 0 0 0 8.25rem;
    }

    .Planes {
        width: 5.063rem;
        height: 1.75rem;
        margin: 11.375rem 22.125rem 4.875rem 0.375rem;
        font-size: 1.313rem;
    }

    .PlanInicia {
        width: 22.375rem;
        height: 4.313rem;
        margin: 4.875rem 9.188rem 0.563rem 0;
        font-size: 3.125rem;
    }

    .PlanIniciaText {
        width: 22rem;
        height: 6.875rem;
        margin: 0.563rem 9.313rem 0.938rem 0.25rem;
        font-size: 1.2rem;
    }

    .VerMs {
        width: 4.813rem;
        height: 1.75rem;
        font-size: 1.313rem;
    }

    .Elipse14 {
        width: 90.344rem;
        height: 90.063rem;
        margin: -17.437rem 0 0 -1.125rem;
        padding: 78.938rem 51rem 3.563rem 6.469rem;
        background: url('../../../assets/Pack/secundario.png') no-repeat -39rem 10.65rem/117.8%;
    }

    .SecondButton {
        padding: 0.622rem 4.102rem 0.628rem 4.023rem !important;
        display: -webkit-box;
        position: absolute;
        margin: -15rem auto 0 auto !important;
    }
}

@media (min-width: 1442px) {
    .TusProductos {
        width: 35rem !important;
        margin: 2.625rem 3.375rem 1.675rem 2.207rem !important;
        font-size: 2.5rem !important;
    }

    .HazloSimpleContainer {
        width: 60.188rem;
        margin: 0;
    }

    .HazloSimpleItem {
        font-size: 2.575rem;
    }

    .HazloSimple {
        width: 40rem;
        height: 4.312rem;
        margin: 28.513rem 0 0px -5.2rem;
        font-size: 3.825rem;
    }

    .ImagesContainer {
        width: 100%;
        margin: auto;
        height: 20rem;
    }

    .ConQuienesTrabajamos {
        height: 4.313rem;
        margin: 1.563rem 0 5rem 0rem;
        font-size: 3.125rem;
    }

    .Background {
        height: auto;
    }

    .Container2 {
        height: 133rem;
        margin: -3.687rem 0 0rem 0.031rem;
    }

    .Rectangulo {
        width: 102.5rem;
        height: 51.25rem;
        margin: 11.625rem auto 19.125rem auto;
        padding: 0 0 0 8.25rem;
    }

    .ComoFuncionaText {
        font-size: 2.2rem;
        width: auto !important;
    }

    .Logo {
        width:295px !important;
        height:48px !important;
    }

    .Rectangle {
        height: fit-content !important;
        width: 96.8%;
        margin: 13.813rem 0 0 8.75rem;
    }

    .HazloSimpleImg {
        width: 55rem;
        height: 55rem;
        margin: 20rem 0 15rem 22%;
    }

    .Contactanos {
        font-size: 2.2rem;
    }

    .ButtonContactanos {
        width: 25.875rem;
        height: 5.563rem;
        margin: 1.125rem 12.125rem 5.625rem 9.707rem !important;
    }

    .Digitaliza {
        font-size: 4rem !important;
    }
}