@font-face {
  font-family: 'NunitoSansLight';
  src: local('NunitoSansLight'), url(./fonts/NunitoSans-Light.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FranciscoSerialBold';
  src: local('FranciscoSerialBold'), url(./fonts/francisco-serial-bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FranciscoSerialExtraBold';
  src: local('FranciscoSerialBold'), url(./fonts/francisco-serial-extrabold-regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSansExtraLight';
  src: local('NunitoSansExtraLight'), url(./fonts/NunitoSans-ExtraLight.ttf) format('truetype');
  font-display: swap;

}

@font-face {
  font-family: 'NunitoSansRegular';
  src: local('NunitoSansRegular'), url(./fonts/NunitoSans-Regular.ttf) format('truetype');
  font-display: swap;

}

@font-face {
  font-family: 'NunitoSansBold';
  src: local('NunitoSansBold'), url(./fonts/NunitoSans-Bold.ttf) format('truetype');
  font-display: swap;

}

@font-face {
  font-family: 'NunitoSansSemiBold';
  src: local('NunitoSansSemiBold'), url(./fonts/NunitoSans-SemiBold.ttf) format('truetype');
  font-display: swap;

}

@font-face {
  font-family: 'NunitoSansExtraBold';
  src: local('NunitoSansExtraBold'), url(./fonts/NunitoSans-ExtraBold.ttf) format('truetype');
  font-display: swap;

}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
  font-display: swap;

}
@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-display: swap;

}
@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-display: swap;

}

@font-face {
  font-family: 'GibsonRegular';
  src: local('GibsonRegular'), url(./fonts/Gibson-Regular.ttf) format('truetype');
  font-display: swap;

}
@font-face {
  font-family: 'gibsonBold';
  src: local('gibsonBold'), url(./fonts/gibson-bold.ttf) format('truetype');
  font-display: swap;

}

.nav-link {
  color:black;
}


.slick-prev,
.slick-next {
  top: 120% !important;
}

.navbar-light .navbar-toggler {
  border: solid 1px #ff8d00 !important;
  border-radius: 50px !important;
}

.navbar-toggler-icon {
  width: 12px !important;
}

.bodyContainer {
  min-height: 450px;
  margin-top:50px !important;
}

.form-check-input:checked {
  color: orangered;
  background-color: orangered;
}

.slick-dots li.slick-active button:before {

  font-size:14px !important;
}
.slick-dots li button:before {
  font-size:14px !important;

}


@media (max-width: 650px) {
  .menuItemSideBar {
    font-family: "MontserratBold";
    font-size:20px;
    color: #2A3645 !important;
  }
.containerSideBar {
  padding:10%;
  max-width: 50%;
}
.MuiDrawer-paperAnchorRight {
width: 50%;
background: #DCEAFC !important;
}
}
@media (min-width: 0px) {
  /* .navbar-collapse.collapse {
    display: block !important;
    padding-bottom: 0;
    overflow: visible !important;
    border-top: 0;
    box-shadow: none;
    width: 115rem;
    height: 100vmax !important;
    opacity: 0.9;
    background-color: #000000;
  } */

  .navbar-nav {
    float: right;
    margin: 0;
  }
}

/* // Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
  /* .navbar-collapse.collapse {
    display: block !important;
    padding-bottom: 0;
    overflow: visible !important;
    border-top: 0;
    box-shadow: none;
    width: 115rem;
    height: 100vmax !important;
    opacity: 0.9;
    background-color: #000000;
  } */

  .navbar-nav {
    float: right;
    margin: 0;
  }
}

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    flex-grow: 0 !important;
  }
  /* .navbar-collapse.collapse {
    display: block !important;
    padding-bottom: 0;
    overflow: visible !important;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 115rem;
    height: 100vmax !important;
    opacity: 0.9;
    background-color: #000000;
  } */

  .navbar-nav {
    float: right;
    margin: 0;
  }
}

/* // Large devices (desktops, 992px and up) */

@media (min-width: 992px) {}

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {

  .rightBottoms {
    display: inherit;
    right: 0px;
    position: absolute;
  }
  .contactBottom {
    border: 1px solid #2A3645;
    color: #2A3645 !important;
    padding: 10px !important;
    margin-right: 30px;
    font-family: 'NunitoSansBold';
    font-size: 1.875rem;
    font-weight: 200;
  }
  .contactBottom:hover {
    background-color: #ff1493;
    background-image: linear-gradient(319deg, #ff1493 0%, #0000ff 37%, #ff8c00 100%);
    color: white !important;
}
  
  .freeBottom {
    background:  #2A3645 !important;
    color: white !important;
    padding:10px !important;
    font-family: 'NunitoSansBold';
    font-size: 1.875rem;
    font-weight: 200;
    margin-right:13px;
  }
  .freeBottom:hover {
    border: 1px solid #2A3645;
    color: #2A3645 !important;
    background: white !important;
  }
}