@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
  margin: auto;
  max-width: 1920px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: #ffffff!important;
  overflow: hidden;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

// .navbar-brand {
//   padding-top: 1.3125rem;
//   padding-bottom: 1.3125rem;
// }

.slick-dots {
  bottom: -70px;

  li {
    button {
      &:before {
        color: #ff862c;
      }
    }

    &.slick-active button:before {
      color: #ff862c;
    }
  }
}


@media (min-width: 0px) {}

/* // Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {}

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {}

/* // Large devices (desktops, 992px and up) */

@media (min-width: 992px) {}

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {}