@media (min-width: 1200px) {
    .containerFooter {
    background-color: #2A3645;
    padding-top:50px;
    padding-bottom:50px;
    padding-left: 10%;
    padding-right:10%;
    max-width: 1920px;
    margin:0 auto;
    text-align: center;
}

.containerColumns {
    display: grid;
    grid-template-columns: auto auto auto auto ;
    grid-gap: 20px;
    padding: 0px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.columnTitle {
    font-family: 'MontserratBold';
    font-size:15px;
    color:white;
    text-align: left;
}
.columnSubtitle {
    font-family: 'MontserratRegular';
    font-size:12px;
    color:white;
    text-align: left;
}
.newsletterContainer {
    text-align:right;

}
.inputFlex {
    display: inline-flex;
    margin-top:20px 
}
.inputNewsletter {
    padding:20px 20px;
    background: transparent;
    color: white;
    border-style: solid;
    border-width:1px 0px 1px 1px;
    border-color: white;
    width: 340px;
    font-size:18px;
    border-radius: 0px !important;
    font-family: 'MontserratRegular';
}
.inputNewsletter::placeholder {
    color: white !important;
    font-size:15px;
}
.inputNewsletter:focus {
    font-size:15px;
    border-color:transparent !important;
}
input:focus { 
    border-color: white !important;
}
.buttonNewsletter {
    background:  #DCEAFC;
    color: black;
    border: none;
    font-family: 'MontserratBold';
    font-size:20px;
    padding:20px;
}
.newsletterTitle {
    font-family: 'MontserratBold';
    font-size: 18px;
    color:white;
    max-width: 350px;
}
.newsletterBox {
    max-width: 533px;
    text-align: left;
    width: 100%;
    display: inline-block;
}
.iconsColumn {
    text-align: left;
}
.iconSocial {
       width: 36px;
    margin-right: 10px;
    margin-top: 30px;
}
.columnSubtitle:hover { 
text-decoration-color: linear-gradient(319deg, #ff1493 0%, #0000ff 37%, #ff8c00 100%) !important;
/* text-decoration-color: red !important; */
text-decoration: underline;
text-decoration-thickness: 3px;
}
}

/* MOBILE */

@media (max-width: 650px) {
    .containerFooter {
    background-color: #2A3645;
    padding-top:50px;
    padding-bottom:50px;
    padding-left: 10%;
    padding-right:10%;
    max-width: 1920px;
    margin:0 auto;
    text-align: center;
}

.containerColumns {
    display: grid;
    grid-template-columns: auto auto  ;
    grid-gap: 20px;
    padding: 0px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.columnTitle {
    font-family: 'MontserratBold';
    font-size:15px;
    color:white;
    text-align: left;
}
.columnSubtitle {
    font-family: 'MontserratRegular';
    font-size:12px;
    color:white;
    text-align: left;
}
.newsletterContainer {
    text-align:right;

}
.inputFlex {
    display: inline-flex;
    margin-top:20px 
}
.inputNewsletter {
    padding:20px 20px;
    background: transparent;
    color: white;
    border-style: solid;
    border-width:1px 0px 1px 1px;
    border-color: white;
    width: 340px;
    font-size:18px;
    border-radius: 0px !important;
    font-family: 'MontserratRegular';
}
.inputNewsletter::placeholder {
    color: white !important;
    font-size:15px;
}
.inputNewsletter:focus {
    font-size:15px;
    border-color:transparent !important;
}
input:focus { 
    border-color: white !important;
}
.buttonNewsletter {
    background:  #DCEAFC;
    color: black;
    border: none;
    font-family: 'MontserratBold';
    font-size:20px;
    padding:20px;
}
.newsletterTitle {
    font-family: 'MontserratBold';
    font-size: 18px;
    color:white;
    max-width: 350px;
}
.newsletterBox {
    max-width: 533px;
    text-align: left;
    width: 100%;
    display: inline-block;
}
.iconsColumn {
    text-align: left;
}
.iconSocial {
       width: 36px;
    margin-right: 10px;
    margin-top: 30px;
}
.columnSubtitle:hover { 
text-decoration-color: linear-gradient(319deg, #ff1493 0%, #0000ff 37%, #ff8c00 100%) !important;
/* text-decoration-color: red !important; */
text-decoration: underline;
text-decoration-thickness: 3px;
}
}


@media screen and (min-width: 750px) and (max-width:1199px)  {
    .containerFooter {
    background-color: #2A3645;
    padding-top:50px;
    padding-bottom:50px;
    padding-left: 10%;
    padding-right:10%;
    max-width: 1920px;
    margin:0 auto;
    text-align: center;
}

.containerColumns {
    display: grid;
    grid-template-columns: auto auto  ;
    grid-gap: 20px;
    padding: 0px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.columnTitle {
    font-family: 'MontserratBold';
    font-size:15px;
    color:white;
    text-align: left;
}
.columnSubtitle {
    font-family: 'MontserratRegular';
    font-size:12px;
    color:white;
    text-align: left;
}
.newsletterContainer {
    text-align:right;

}
.inputFlex {
    display: inline-flex;
    margin-top:20px 
}
.inputNewsletter {
    padding:20px 20px;
    background: transparent;
    color: white;
    border-style: solid;
    border-width:1px 0px 1px 1px;
    border-color: white;
    width: 340px;
    font-size:18px;
    border-radius: 0px !important;
    font-family: 'MontserratRegular';
}
.inputNewsletter::placeholder {
    color: white !important;
    font-size:15px;
}
.inputNewsletter:focus {
    font-size:15px;
    border-color:transparent !important;
}
input:focus { 
    border-color: white !important;
}
.buttonNewsletter {
    background:  #DCEAFC;
    color: black;
    border: none;
    font-family: 'MontserratBold';
    font-size:20px;
    padding:20px;
}
.newsletterTitle {
    font-family: 'MontserratBold';
    font-size: 18px;
    color:white;
    max-width: 350px;
}
.newsletterBox {
    max-width: 533px;
    text-align: left;
    width: 100%;
    display: inline-block;
}
.iconsColumn {
    text-align: left;
}
.iconSocial {
       width: 36px;
    margin-right: 10px;
    margin-top: 30px;
}
.columnSubtitle:hover { 
text-decoration-color: linear-gradient(319deg, #ff1493 0%, #0000ff 37%, #ff8c00 100%) !important;
/* text-decoration-color: red !important; */
text-decoration: underline;
text-decoration-thickness: 3px;
}
}